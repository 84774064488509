import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Skills from "./components/Skills";
import Cloud from "./components/Cloud";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Web from "./components/Web";
import Projects from "./components/Projects";
import Tab from "./components/Tab";
import Bash from "./components/Bash";
import Xcel from "./components/Xcel";
import Python from "./components/Python";
import Sql from "./components/Sql";
import Covid from "./components/Covid";
import { Routes, Route } from "react-router-dom";
import React from "react";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Skills" element={<Skills />} />
        <Route path="/Cloud" element={<Cloud />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Bash" element={<Bash />} />
        <Route path="/Tab" element={<Tab />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Web" element={<Web />} />
        <Route path="/Xcel" element={<Xcel />} />
        <Route path="/Python" element={<Python />} />
        <Route path="/Sql" element={<Sql />} />
        <Route path="/Covid" element={<Covid />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
