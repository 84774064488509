import React, { useState } from "react";
import Logo from "../assets/Logo.png";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { Link } from "react-scroll";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Resume from "../assets/Resume_2022.pdf";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <nav className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#000] text-[#fff] pb-5">
      {/*logo*/}
      <div>
        <img src={Logo} alt="Colin Powers Logo" style={{ width: "190px" }} />
      </div>

      {/* Navigation Menu */}
      <ul className="hidden md:flex text-2xl text-{#fff]">
        {/* Home Link */}
        <li className="hover:text-green-400">
          <a href="/">Home</a>
        </li>
        {/*Projects Link*/}
        <li>
          <a href="/Projects">Projects</a>
        </li>
        {/* Skills Link */}
        <li className="hover:text-green-400">
          <a href="/Skills">Skills</a>
        </li>
        {/*Contact Link */}
        <li className="hover:text-green-400">
          <a href="/Contact">Contact</a>
        </li>
      </ul>

      {/*Hamburger Menu */}
      <div onClick={handleClick} className="md:hidden z-10 cursor-pointer">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full bg-[#000] flex flex-col items-center text-2xl"
        }
      >
        <li className="py-2">
          <a href="/">Home</a>
        </li>
        <li className="py-2 text 4xl">
          <a href="/Skills">Skills</a>
        </li>
        <li className="py-2 text 4xl">
          <a href="/Projects">Projects</a>
        </li>
        <li className="py-2 text 4xl">
          <a href="/Contact">Contact</a>
        </li>
      </ul>

      {/* Social Icons */}
      <div className="hidden md:flex fixed flex-col top-[35%] left-0">
        {/* LinkedIn Icon*/}
        <li className="w-[160px] h-[60] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
          <a
            className="flex justify-between items-center w-full text-[#fff]"
            href="https://www.linkedin.com/in/cm-powers/"
            target="_blank"
          >
            LinkedIn <FaLinkedin size={30} />
          </a>
        </li>

        {/* Github Icon*/}
        <li className="w-[160px] h-[60] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
          <a
            className="flex justify-between items-center w-full text-[#fff]"
            href="https://github.com/Colin-Powers"
            target="_blank"
          >
            GitHub <FaGithub size={30} />
          </a>
        </li>

        {/*Email Icon */}
        <li className="w-[160px] h-[60] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
          <a
            className="flex justify-between items-center w-full text-[#fff]"
            href="mailto:powers.golden@gmail.com"
            target="_blank"
          >
            Email <HiOutlineMail size={30} />
          </a>
        </li>

        {/* Resume Icon */}
        <li className="w-[160px] h-[60] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
          <a
            className="flex justify-between items-center w-full text-[#fff]"
            href="https://colpowres.com"
            target="_blank"
          >
            Resume <BsFillPersonLinesFill size={30} />
          </a>
        </li>
      </div>
    </nav>
  );
};

export default Navbar;
