import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import Headshot from "../assets/headshot.png";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#000]">
      {/* Home Info Start */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col items-center justify-center h-full pt-10">
        <img
          src={Headshot}
          alt="Colin Powers"
          className="sm:w-[300px] w-[150px] mx-auto rounded-full"
        />
        <h1 className="text-4xl sm:text-7xl font-bold text-[#fff]">
          Colin Powers
        </h1>
        <h2 className="text-2xl sm:text-4xl font-bold text-green-400 pb-3">
          Data Solutions
        </h2>
        <h3 className="text-2xl text-[#fff] py-3 border-t border-green-400">
          My name is Colin, welcome to my website. I'm a Data Analyst with a
          passion for technology and finding solutions to difficult problems. To
          date, I have earned four industry certifications and I am excited to
          continue to learn new tools and technologies. On this website you can
          find projects that I have completed using SQL, Excel, Tableau, Python,
          React, Terraform, and various AWS services.
        </h3>
      </div>
      {/* Home Info End */}
    </div>
  );
};

export default Home;
