import Remove from "../assets/remove.png";
import Duplicates from "../assets/duplicates.png";
import Find from "../assets/find_replace.png";
import IF from "../assets/IF.png";
import Age from "../assets/age_pivot.png";
import Table from "../assets/age_table.png";
import Recommend from "../assets/reccommend.png";
import Dash from "../assets/bike_dash.png";
const Xcel = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* IAM Groups & Users Container */}
          <h1 className="text-3xl my-5 text-center">
            Clean and Explore Bike Purchase Data with Excel
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I use Microsoft Excel to clean and explore bike
            purchase data. The goal of this project is to help a bike business
            understand their customer base better. If you would like to check
            out the original data or the completed file please go{" "}
            <a
              href="https://github.com/Colin-Powers/Bike-Purchase-Excel"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>

          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Cleaning the Data:
          </h2>
          <p className="text-2xl my-5 ">
            The first step in cleaning the dataset was to remove duplicates by
            using the "Remove Duplicates" button found on the data tab, 26
            duplicate values were found and removed from the data.
          </p>
          <img src={Remove} alt="Remove Duplicates Button" className="my-2" />
          <img
            src={Duplicates}
            alt="26 Duplicate Data Points Removed"
            className="my-2"
          />
          <p className="text-2xl my-5">
            The next step in cleaning the data was to replace the "M" and "S"
            values in the Marital Status column with "Married" and "Single" to
            eliminate any potential confusion. To do this I highlighted the
            "Marital Status" column and hit (Ctrl + h) on the keyboard to bring
            up the find and replace tool.
          </p>
          <img src={Find} alt="Find and Replace Tool" className="my-2" />
          <p className="text-2xl my-5">
            I used the same process to replace the "M" and "F" in the Gender
            column with "Male" and "Female", and the "Yes" and "No" in the
            "Purchased Bike" column to "Purchase" and "No Purchase".
          </p>
          <p className="text-2xl my-5">
            After cleaning the Gender column, I moved onto the Age data. I used
            IF statements to create three age ranges, "Young Adult", "Middle
            Age", and "Senior" and placed them into a new "Age Bracket" column.
            The new column will make it easier to understand and visualize how
            age plays a part in bike purchases later in the project.
          </p>
          <img src={IF} alt="Age Bracket IF Statements" className="my-2" />
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating Pivot Tables:
          </h2>
          <p className="text-2xl my-5">
            For this project I created three different pivot tables with the
            goal of determining how the age, gender, commute distance, and
            income of a customer impacts if they purchase a bike or not. The age
            pivot table was constucted using the following selections:{" "}
          </p>
          <img
            src={Age}
            alt="Customer Age Pivot Table Selections"
            className="my-2"
          />
          <img src={Table} alt="Customer age Pivot Table" className="my-2" />
          <p className="text-2xl my-5">
            The pivot tables for the other categories were built using the same
            process.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Creating the Dashboard:
          </h2>
          <p className="text-2xl my-5">
            After I created all of the pivot tables I used the recommended chart
            tool
          </p>
          <img src={Recommend} alt="Recommended Chart Tool" className="my-2" />
          <p className="text-2xl my-5">
            to quickly create a visualization for each one. I then moved all of
            the visualizations to their own tab and added three slicers that
            will allow the dashboard user to filter the visualizations based on
            marrital status, education, and if the customer is a home owner.
          </p>
          <img src={Dash} alt="Bike Purchase Dashboard" className="my-2" />
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Analysis:
          </h2>
          <p className="text-2xl my-5">
            The work done by creating the Dashboard makes it easy for a user to
            answer a variety of questions. For instance, without applying any of
            the slicer filters to the charts we can see that middle aged men,
            with a commute of less than a mile, and a higher annual income
            purchase bikes at a much higher rate. By applying the slicer filters
            you can dig even further into this demographic.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Xcel;
