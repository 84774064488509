const Bash = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* Cloud Resume Challenge Container */}
          <h1 className="text-3xl my-5 text-center">
            Bash Script for DevOps Environment Setup
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            This script is designed to efficiently set up a development
            environment on a CentOS or Amazon Linux 2 operating system. It
            automates the installation and configuration of commonly used tools
            such as, Terraform, Python, Git, Docker, and the AWS CLI.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bash;
