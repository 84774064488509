import React from "react";
import SecurityGroup from "../assets/SecurityGroup.png";
import html_exp from "../assets/html_exp.png";
import css_exp from "../assets/css_exp.png";
import www_bucket from "../assets/www_bucket.png";
import www_public from "../assets/www_public.png";
import www_hosting from "../assets/www_hosting.png";
import www_policy from "../assets/www_policy.png";
import www_data from "../assets/www_data.png";
import root_bucket from "../assets/root_bucket.png";
import root_redirect from "../assets/root_redirect.png";
import www_distro from "../assets/www_distro.png";
import root_distro from "../assets/root_distro.png";
import hosted_zone from "../assets/hosted_zone.png";
import a_record from "../assets/a_record.png";
import ssl_cert from "../assets/ssl_cert.png";
import custom_ssl from "../assets/custom_ssl.png";
import visitor_html from "../assets/visitor_html.png";
import visitor_js from "../assets/visitor_js.png";
import dynamodb from "../assets/dynamodb.png";
import get_lambda from "../assets/get_lambda.png";
import put_lambda from "../assets/put_lambda.png";

const Projects = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* Cloud Resume Challenge Container */}
          <h1 className="text-3xl my-5 text-center">
            The Cloud Resume Challenge
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            The Cloud Resume Challenge is a cloud service project spec designed
            by Forrest Brazeal. The goal of the challenge is to build a resume
            using HTML and CSS and deploy it using various cloud technologies.
            To view the original project requirements, please go{" "}
            <a
              href="https://cloudresumechallenge.dev/docs/the-challenge/aws/"
              className="text-green-400"
              target="_blank"
            >
              here.
            </a>
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Set Up
          </h2>
          <p className="text-2xl my-5">
            Before I started working on deploying my resume I installed the AWS
            CLI, AWS Vault, Terraform, and AWS SAM on my machine. The AWS
            Command Line Interface allows you to interact with AWS services
            using commands in your terminal program. AWS Vault is a tool to
            securely store and access multiple AWS credentials from your command
            line. Terraform and AWS SAM are infrastructure as code tools.
            Terraform was used to deploy the frontend of the project, and AWS
            SAM was used for the backend. You can learn more about AWS Vault{" "}
            <a
              href="https://github.com/99designs/aws-vault"
              target="_blank"
              className="text-green-400"
            >
              here,
            </a>{" "}
            more about the AWS CLI{" "}
            <a
              href="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-welcome.html"
              target="_blank"
              className="text-green-400"
            >
              {" "}
              here,{" "}
            </a>
            more about Terraform{" "}
            <a
              href="https://www.terraform.io"
              target="_blank"
              className="text-green-400"
            >
              here,{" "}
            </a>
            and more about AWS SAM{" "}
            <a
              href="https://aws.amazon.com/serverless/aws-sam/"
              target="_blank"
              className="text-green-400"
            >
              here.
            </a>
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            1 - Certification:
          </h2>
          <p className="text-2xl my-5">
            The first requirement of the challenge is to earn the AWS Cloud
            Practitioner certification. I earned the certification in August of
            2022, you can check my credential{" "}
            <a
              href="https://aw.certmetrics.com/amazon/public/verification.aspx"
              className="text-green-400"
              target="_blank"
            >
              here{" "}
            </a>{" "}
            using this validation number: LNHWSEBCSE1EQL5V
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            2 & 3 - HTML & CSS:
          </h2>
          <p className="text-2xl my-5">
            The resume deployed in this challenge was built using simple HTML
            and CSS.
            <img src={html_exp} alt="HTML example" className="my-5 inline" />
            <img src={css_exp} alt="CSS example" className="my-5" />
            You can visit{" "}
            <a
              href="https://github.com/Colin-Powers/The-Cloud-Resume-Challenge"
              className="text-green-400"
              target="_blank"
            >
              GitHub{" "}
            </a>{" "}
            if you would like to check out the source code, and you can visit{" "}
            <a
              href="https://www.colpowres.com"
              className="text-green-400"
              target="_blank"
            >
              here{" "}
            </a>{" "}
            to demo the resume.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            4 - Amazon S3 Static Website:
          </h2>
          <p className="text-2xl my-5">
            Amazon Simple Storage Service (S3) is an object storage service that
            provides scalability, data availability, security, and performance.
            It can be used to protect any amount of data for a variety of use
            cases, such as data lakes, websites, mobile applications, and big
            data analytics. For this project I used S3 to host and store the
            HTML and CSS files for my resume. I deployed my S3 infrastructure
            using the following Terraform code:{" "}
            <img src={www_bucket} alt="HTML example" className="my-5" />
            <img src={www_public} alt="CSS example" className="my-5" />
            <img src={www_hosting} alt="CSS example" className="my-5" />
            <img src={www_policy} alt="CSS example" className="my-5" />
            <img src={www_data} alt="CSS example" className="my-5" />
            <img src={root_bucket} alt="CSS example" className="my-5" />
            <img src={root_redirect} alt="CSS example" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            5 - Cloudfront & HTTPS:
          </h2>
          <p className="text-2xl my-5">
            Amazon CloudFront is a content delivery network service, or CDN.
            CDN's are geographically distributed groups of servers that cache
            content close to end users providing lower latency and a better user
            experience. Using CloudFront also alows for the use of a HTTPS
            connection which provides increased security compared to HTTP. I
            deployed my CloudFront infrastructure using the following Terraform
            code:{" "}
            <img
              src={www_distro}
              alt="www CloudFront Terraform Code"
              className="my-5"
            />
            <img
              src={root_distro}
              alt="Root CloudFront Terrafomr Code"
              className="my-5"
            />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            6 - Route 53 & DNS:
          </h2>
          <p className="text-2xl my-5">
            Amazon Route 53 is a Domain Name System, or DNS, web service. DNS is
            a naming database where internet domain names are stored and
            translated into IP addresses. Route 53 will connect user requests to
            internet applications running on either AWS services, or on premises
            servers. I used Route 53 to point my domain name, www.colpowres.com,
            to my CloudFront infrastructure. I deployed my Route 53
            infrastructure using the following Terraform code:{" "}
            <img
              src={hosted_zone}
              alt="Terraform Route 53 Hosted Zone Code"
              className="my-5"
            />
            <img
              src={a_record}
              alt="Terraform A Record Code"
              className="my-5"
            />
            I used the AWS management console to get a SSL certificate through
            Amazon Certificate Manager. A SSL certificate authenticates the
            identity of a website and encrypts information sent to the server.
            <img src={ssl_cert} alt="SSL Certificate" className="my-5" />
            Once Amazon Certificate Manager issued the SSL certificates I linked
            them to my CloudFront distributions.
            <img
              src={custom_ssl}
              alt="Custom SSL Certificate CloudFront"
              className="my-5"
            />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            7 - Javascript & Visitor Count
          </h2>
          <p className="text-2xl my-5">
            The backend portion of this project is to store a visitor count in a
            DynamoDB table and to update, access, and display the value using
            API's and Javascript. The actual display of the value on the resume
            was accomplished using the following Javascript and HTML code:
            <img
              src={visitor_html}
              alt="HTML Code for Visitor Count"
              className="my-5"
            />
            <img
              src={visitor_js}
              alt="JS Code for Visitor Count"
              className="my-5"
            />
            The Javascript code first calls the API to update the visitor count,
            then calls the API to get and return the current visitor count and
            stores that value in a local variable that is called in the HTML
            code.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            8 - Database & DynamoDB
          </h2>
          <p className="text-2xl my-5">
            Amazon DynamoDB is a fully managed, serverless, key-value, NoSQL
            database that offers built-in security, continuous backups,
            automated multi-Region replication, in-memory caching, and data
            import and export tools. I used a simple DynamoDB table to store my
            visitor count value and deployed it using the following YAML code:
            <img src={dynamodb} alt="DynamoDB YAML Code" className="my-5" />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            9 - API Gateway
          </h2>
          <p className="text-2xl my-5">
            Amazon API Gateway is a fully managed service that allows developers
            to create, publish, maintain, monitor, and secure APIs. An API,
            Application Programming Interface, simplifys development by allowing
            your product or service to communicate with other products and
            services without having to know how they're implemented. In this
            case, I used API Gateway to allow my website to communicate with AWS
            Lambda and DynamoDB by invoking a simple URL. AWS SAM created a
            basic API for me when I chose the Hello World template while
            initializing the backend of this project. The template API only
            needed a few minor method updates, GET & PUT, for it to work for my
            purposes.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            10 - Lambda Functions
          </h2>
          <p className="text-2xl my-5">
            Amazon Lambda is a serverless, event-driven compute service that
            allows you to run code for almost any kind of application or backend
            service without provisioning or managing servers. I used two
            seperate Lambda functions for this project. The first one updates
            the DynamoDB visitor count value each time my website is visited.
            The second value simply gets the current visitor value from DynamoDB
            and returns it. I deployed the Lambda functions using the following
            YAML code:
            <img
              src={put_lambda}
              alt="Put Function YAML Code"
              className="my-5"
            />
            <img
              src={get_lambda}
              alt="Get Function YAML Code"
              className="my-5"
            />
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            11 - Infrastructure as Code
          </h2>
          <p className="text-2xl my-5">
            Infrastructure as Code uses DevOps principles and versioning to
            define and deploy infrastructure. IaC generates the same environment
            every time it is deployed, providing conistency that can't be found
            using a manual process. For this project I used two different IaC
            tools, AWS SAM and Terraform. AWS SAM is Amazon's IaC tool that
            works exclusively with their cloud platform. The benefit of
            Terraform is it can be used with various cloud providers.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            12 - Version Control & GitHub
          </h2>
          <p className="text-2xl my-5">
            GitHub is a cloud-based Git repository hosting service that makes it
            easier for individuals and teams to use Git for both version control
            and collaboration. Version control refers to the practice of
            tracking and managing changes to software code over time. You can
            check out the GitHub repository for this project{" "}
            <a
              href="https://github.com/Colin-Powers/Cloud-Resume"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>

          {/* Cloud Resume Challenge Container End */}

          {/* Portfolio Deployment Project Container */}

          {/* Portfolio Project Container End */}
        </div>
      </div>
    </div>
  );
};

export default Projects;
