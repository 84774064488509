import Table from "../assets/import_table.png";
import Path from "../assets/copy_path.png";
import Fatality from "../assets/fatality_query.png";
import FatalityTab from "../assets/fatality_table.png";
import Death from "../assets/infection.png";
import DeathTab from "../assets/infection_table.png";
import Top from "../assets/top_query.png";
import TopTab from "../assets/top_table.png";
import DeathTop from "../assets/death_top.png";
import DeathTopTable from "../assets/death_top_table.png";

import React, { useRef, useEffect } from "react";

const { tableau } = window;

const Covid = () => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const url =
    "https://public.tableau.com/views/Fatality_16857351584690/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url2 =
    "https://public.tableau.com/views/Infection_16857372369750/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url3 =
    "https://public.tableau.com/views/TopTen_16859135690660/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url4 =
    "https://public.tableau.com/views/TopTenDeath/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";

  const initViz = () => {
    new tableau.Viz(ref.current, url, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz2 = () => {
    new tableau.Viz(ref2.current, url2, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz3 = () => {
    new tableau.Viz(ref3.current, url3, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz4 = () => {
    new tableau.Viz(ref4.current, url4, {
      width: "100%",
      height: "300px",
    });
  };

  useEffect(initViz, []);
  useEffect(initViz2, []);
  useEffect(initViz3, []);
  useEffect(initViz4, []);
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* IAM Groups & Users Container */}
          <h1 className="text-3xl my-5 text-center">
            Exploring COVID Data with PostgreSQL & Tableau
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I used PostgreSQL to explore COVID data and Tableau
            to visualize it. PostgreSQL is an enterprise class open source
            relational database that supports SQL querying. SQL stands for
            structured query language and is a programming language for storing
            and processing information in a relational database. Tableau is a
            visual analytics platform. The data for this project was found{" "}
            <a
              href="https://ourworldindata.org/covid-deaths"
              target="_blank"
              className="text-green-400"
            >
              here.
            </a>{" "}
            If you would like to check out the original data or the completed
            file please go{" "}
            <a
              href="https://github.com/Colin-Powers/SQL-COVID"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>

          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Prepping the Original File with Excel:
          </h2>
          <p className="text-2xl my-5">
            Before starting my analysis in PostgreSQL, I made some adjustments
            to the original data file using Excel. I split the single file into
            two seperate CSV files, one related to COVID deaths and the other to
            vaccinations.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Importing the Data:
          </h2>
          <p className="text-2xl my-5">
            To import the .csv files into PostgreSQL I used the following
            queries:
          </p>
          <img src={Table} alt="Import table query" className="my-2" />
          <img src={Path} alt="Copy Path Query" className="my-2" />
          <p className="text-2xl my-5">
            The same process was used to import the vaccination table.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Exploring the Data:
          </h2>
          <p className="text-2xl my-5">
            <span className="text-green-400">Question:</span> What was the COVID
            fatality rate over time in the United States?
          </p>
          <img
            src={Fatality}
            alt="Covid Fatality Rate Query"
            className="my-2"
          />
          <img
            src={FatalityTab}
            alt="Covid Fatality Rate Table"
            className="my-2"
          />
          <p className="text-2xl my-5">
            To answer this question I created a new column by deviding the total
            deaths by the total cases (multiplied by 100) and filtered to only
            view data from the United States. The following is a visualization
            that I created in Tableau that makes it easier to quickly understand
            the information in the table above.
          </p>
          <div ref={ref} className="w-full h-[300px] my-5" />
          <p className="text-2xl my-5">
            <span className="text-green-400">Question:</span> How did the
            percentage of people infected in the United States change over time?
          </p>
          <img src={Death} alt="Infection Rate Query" className="my-2" />
          <img src={DeathTab} alt="Infection Rate Table" className="my-2" />
          <p className="text-2xl my-5">
            To answer this question I created a new column by deviding the total
            cases by the population (multiplied by 100) and filtered to only
            view data from the United States. The following visualization also
            makes it easier and quicker to understand the trend.
          </p>
          <div ref={ref2} className="w-full h-[300px] my-5" />
          <p className="text-2xl my-5">
            <span className="text-green-400">Question:</span> What countries had
            the highest percentage of population that was infected with COVID at
            some point?
          </p>
          <img src={Top} alt="Top Ten Infection Rate Query" />
          <img
            src={TopTab}
            alt="Top Ten Infection Rate Table"
            className="my-2"
          />
          <p className="text-2xl my-5">
            To answer this question I used the same calculation as the previous
            question, deviding total cases by the population and multiplying by
            100, but wrapped it in the MAX function. This function will find the
            most current and highest infection percentage for each location. The
            following bar graph shows the top ten countries with the Highest
            COVID Infection Rate to Date.
          </p>

          <div ref={ref3} className="w-full h-[300px] my-5" />
          <p className="text-2xl my-5">
            <span className="text-green-400">Question: </span> What countries
            had the highest COVID death rate?
          </p>
          <img
            src={DeathTop}
            alt="Top Ten Countries in COVID Deaths Query"
            className="my-2"
          />
          <img
            src={DeathTopTable}
            alt="Top Ten Countries in COVID Deaths Table"
            className="my-2"
          />
          <p className="text-2xl my-5">
            To answer this question I used the same process as the previous
            question but applied the MAX function to total_deaths instead. You
            can see the results in the bar graph below.
          </p>
          <div ref={ref4} className="w-full h-[300px] my-5" />
        </div>
      </div>
    </div>
  );
};

export default Covid;
