import React from "react";
import Knick from "../assets/knick.png";
import html_exp from "../assets/html_exp.png";
import css_exp from "../assets/css_exp.png";

const Web = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* React Website Container */}
          <h1 className="text-3xl my-5 text-center">React Website</h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl py-5 ">
            Built a website for my Mother's craft business using React and
            Tailwind CSS. React is a Javascript library for creating user
            interfaces. Tailwind CSS is a framework that allows inline styling
            without writing custom CSS.{" "}
            <img
              src={Knick}
              alt="Knick Knack Krafts"
              className="py-3 w-[300px]"
            />
            You can check out the website{" "}
            <a
              href="https://www.knicknackrafts.com"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            , and the code{" "}
            <a
              href="https://github.com/Colin-Powers/Knick_Knack_Krafts"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Components:
          </h2>
          <p className="text-2xl py-5">
            The home page of the website includes an image slider that allows
            the user to cycle through five different product images. Each
            product page is the same reusable component of a simple photo
            gallery. The Contact page utilizes a service called EmailJS to
            forward the contents of the form to an email service. You can read
            more about EmailJS{" "}
            <a
              href="https://www.emailjs.com"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            . Many of the components created for this website were used again
            for this portfolio website.
          </p>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Deployment:
          </h2>
          <p className="text-2xl py-5">
            This project was deployed using the same process and terraform code
            that I used for the Cloud Resume Challenge. You can read more about
            that process{" "}
            <a href="/Cloud" className="text-green-400">
              here
            </a>
            .
          </p>
          {/* Cloud Resume Challenge Container End */}

          {/* Portfolio Deployment Project Container */}

          {/* Portfolio Project Container End */}
        </div>
      </div>
    </div>
  );
};

export default Web;
