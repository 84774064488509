const Projects = () => {
  return (
    <div className="fixed w-full h-full flex justify-between items-center px-4 bg-[#000] text-[#fff]">
      <ul className="mx-auto text-3xl">
        <li className="py-2 hover:text-green-400">
          <a href="/Cloud">The Cloud Resume Challenge</a>
        </li>
        <li className="py-2 hover:text-green-400">
          <a href="/Covid">SQL</a>
        </li>
        <li className="py-2 hover:text-green-400">
          <a href="/Python">Python</a>
        </li>
        <li className="py-2 hover:text-green-400">
          <a href="/Xcel">Microsoft Excel</a>
        </li>
        <li className="py-2 hover:text-green-400">
          <a href="/Web">React</a>
        </li>
        <li className="py-2 hover:text-green-400">
          <a href="/Tab">Tableau</a>
        </li>
      </ul>
    </div>
  );
};

export default Projects;
