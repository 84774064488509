import React, { useRef, useEffect } from "react";

const { tableau } = window;

const Tab = () => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const url =
    "https://public.tableau.com/views/SeattleAverageNightlyRatebyZipcode/Airbnb?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url2 =
    "https://public.tableau.com/views/SeattleAverageNightlyRatebyZipcodeMap/Airbnb?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url3 =
    "https://public.tableau.com/views/SeattleAverageNightlyRateof98134ZipcodebyMonth/Airbnb?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";
  const url4 =
    "https://public.tableau.com/views/SeattleAverageNightlyRatein98134byBedroom/Airbnb?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link";

  const initViz = () => {
    new tableau.Viz(ref.current, url, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz2 = () => {
    new tableau.Viz(ref2.current, url2, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz3 = () => {
    new tableau.Viz(ref3.current, url3, {
      width: "100%",
      height: "300px",
    });
  };
  const initViz4 = () => {
    new tableau.Viz(ref4.current, url4, {
      width: "100%",
      height: "300px",
    });
  };

  useEffect(initViz, []);
  useEffect(initViz2, []);
  useEffect(initViz3, []);
  useEffect(initViz4, []);

  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* IAM Groups & Users Container */}
          <h1 className="text-3xl my-5 text-center">
            Visualize Seattle Airbnb Data with Tableau
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I use Tableau to visualize Seattle home listing data
            from Airbnb. The data set for this project was downloaded from{" "}
            <a
              href="https://www.kaggle.com/datasets/airbnb/seattle"
              target="_blank"
              className="text-green-400"
            >
              kaggle.com
            </a>
            , an online community platform that allows users to collaborate with
            each other and find and publish datasets. The goal of this project
            is to create visualizations that would help a potential Airbnb
            investor get an idea of where the best areas in Seattle would be to
            purchase properties based on information like location, number of
            bedrooms, and rental price.
          </p>

          <div ref={ref} className="w-full h-[300px] my-5" />
          <div ref={ref2} className="w-full h-[300px] my-5" />
          <div ref={ref3} className="w-full h-[300px] my-5" />
          <div ref={ref4} className="w-full h-[300px] my-5" />

          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Analysis:
          </h2>
          <p className="text-2xl my-5 ">
            The first Tableau visualization included above shows the Average
            Nightly Rate of Seattle Airbnb's split up by zipcode. The bargraph
            makes it easy to see that the 98134 zipcode has the highest average
            at $206 a night.
          </p>
          <p className="text-2xl my-5 ">
            The second visualization shows a map of the Seattle area with each
            zipcode shaded a different color, this helps the user to determine
            which parts of Seattle each of the zipcodes represent. For instance,
            the 98134 zipcode that has the highest average rate per night
            includes the SoDo neighborhood in downtown Seattle. The SoDo
            neighborhood includes T-Mobile Park, where the Seattle Mariners play
            their home games, and Lumen Field, where the Seattle Seahawks play
            their home games. Knowing this information helps to provide insight
            as to why the average nightly rates are so high in that area.
          </p>
          <p className="text-2xl my-5 ">
            The third visualization shows the change in Nightly rate for the
            98134 Zipcode by Month. From the linegraph it is easy to determine
            that the demand for Airbnbs in that area stays fairly consistent
            throughout the year.
          </p>
          <p className="text-2xl my-5 ">
            The final visualization breaks down the average nightly rate in the
            98134 zipcode based on the number of bedrooms a property has. The
            bargraph shows that two bedroom properties bring in substantially
            more per night than single bedroom properties.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tab;
