import Email from "../assets/email_query.png";
import Rating from "../assets/rating_query.png";
import EmailTab from "../assets/email_table.png";
import RatingTab from "../assets/rating_table.png";
import Where from "../assets/where_query.png";
import WhereTab from "../assets/where_table.png";
import Limit from "../assets/limit_query.png";
import LimitTab from "../assets/limit_tab.png";
import Count from "../assets/payment_count.png";
import CountTab from "../assets/count_table.png";
import CitTab from "../assets/city_product.png";
import CitGraph from "../assets/city_graph.png";
import Line from "../assets/orders_line.png";
import Quantity from "../assets/quantity.png";
const Sql = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* IAM Groups & Users Container */}
          <h1 className="text-3xl my-5 text-center">
            Exploring DvD Rental Data with PostGreSQL
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I used PostGreSQL to explore fake DvD Rental data
            and to answer hypothetical business questions. If you would like to
            check out the original data or the completed file please go{" "}
            <a
              href="https://github.com/Colin-Powers/Python-Sales-Analysis"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>

          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Answering Business Questions:
          </h2>
          <p className="text-2xl my-5 ">
            <span className="text-green-400">Situation:</span> We want to send
            out a promotional email to our existing customers.
          </p>
          <img
            src={Email}
            alt="SELECT statement for email campaign"
            className="my-2"
          />
          <img src={EmailTab} alt="Table for email campaign" className="my-2" />
          <p className="text-2xl my-5">
            Using a SELECT statement I quickly queried the customer table for
            the necessary information for a promotional email campaign.
          </p>
          <p className="text-2xl my-5">
            <span className="text-green-400">Situation:</span> We want to know
            the types of film ratings we have in our database. What ratings do
            we have available?
          </p>
          <img src={Rating} alt="SELECT DISTINCT statement" className="my-2" />
          <img src={RatingTab} alt="SELECT DISTINCT table" className="my-2" />

          <p className="text-2xl my-5">
            Using a SELECT DISTINCT statement I quickly discovered how many
            different film ratings the DvD store offers.
          </p>
          <p className="text-2xl my-5">
            <span className="text-green-400">Situation:</span> A customer forgot
            their wallet at our store! We need to track down their email to
            inform them. What is the email for the customer with the name Nancy
            Thomas?
          </p>
          <img src={Where} alt="Where Statement for email" className="my-2" />
          <img src={WhereTab} alt="Where table for email" className="my-2" />
          <p className="text-2xl my-5">
            Using a SELECT and WHERE statement I quickly located this customers
            email information.
          </p>
          <p className="text-2xl my-5">
            <span className="text-green-400">Situation: </span> We want to
            reward our first 10 paying customers. What are the customer ids of
            the first 10 customers who made a payment?{" "}
          </p>
          <img src={Limit} alt="Limit Query" className="my-2" />
          <img src={LimitTab} alt="Limit Query Table" className="my-2" />
          <p className="text-2xl my-5">
            Using the SELECT, ORDER BY, and LIMIT statements I was able to
            isolate the first ten customers to make a payment.
          </p>
          <p className="text-2xl my-5">
            <span className="text-green-400">Situation:</span> How many payment
            transactions were greater than $5.00?
          </p>
          <img src={Count} alt="Payment Amount Count Query" className="my-2" />
          <img
            src={CountTab}
            alt="Payment Amount Count Table"
            className="my-2"
          />
          <p className="text-2xl my-5">
            Using the SELECT, COUNT, and WHERE statements I quickly found the
            number of payments greater than $5.00.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sql;
