import Merge from "../assets/merge_csv.png";
import Nan from "../assets/nan.png";
import Order from "../assets/order_date.png";
import Column from "../assets/new_column.png";
import Sales from "../assets/month_sales.png";
import SalGraph from "../assets/month_graph.png";
import CitTab from "../assets/city_product.png";
import CitGraph from "../assets/city_graph.png";
import Line from "../assets/orders_line.png";
import Quantity from "../assets/quantity.png";
const Python = () => {
  return (
    <div
      name="Projects"
      className="flex w-full h-full bg-[#000] text-[#fff] pt-60"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col w-full h-full">
        <div className="pb-8 text-left">
          {/* IAM Groups & Users Container */}
          <h1 className="text-3xl my-5 text-center">
            Sales Analysis using Python
          </h1>
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            About:
          </h2>
          <p className="text-2xl my-5 ">
            In this project I use Python and Jupyter Notebook to clean and
            explore fake sales data. I prefer to use Jupyter Notebook because it
            allows you to see the code and its output next to one another. The
            goal of this project is to help a hypothetical business understand
            their customer base better. If you would like to check out the
            original data or the completed file please go{" "}
            <a
              href="https://github.com/Colin-Powers/Python-Sales-Analysis"
              target="_blank"
              className="text-green-400"
            >
              here
            </a>
            .
          </p>

          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Cleaning the Data:
          </h2>
          <p className="text-2xl my-5 ">
            The first step in cleaning the dataset was to merge 12 months of
            sales data into a single csv file.
          </p>
          <img
            src={Merge}
            alt="Merge all csv files into one"
            className="my-2"
          />
          <p className="text-2xl my-5">
            The next step in cleaning the data was to remove all of the NaN
            values from the data. NaN stands for "Not a Number" and is an
            undefined value that will throw an error if used in a calculation.
          </p>
          <img src={Nan} alt="Remove NaN values" className="my-2" />
          <p className="text-2xl my-5">
            After removing all of the NaN values I removed all of the orders
            that did not have an order date. In this data, incomplete order
            dates were represented with "Order Date" instead of an actual date.
          </p>
          <img src={Order} alt="Remove bad order date data" className="my-2" />
          <p className="text-2xl my-5">
            Next, I moved on to adding a month and a city column that made
            future analysis and visualizations easier to create.
          </p>
          <img src={Column} alt="New month and city columns" className="my-2" />
          <h2 className="text-2xl my-5 border-b-2 border-green-400 inline">
            Analysis:
          </h2>
          <p className="text-2xl my-5">
            The first question I answered was "In what month were sales the
            highest?" by creating a table and a bar graph.
          </p>
          <img
            src={Sales}
            alt="Customer Age Pivot Table Selections"
            className="my-2"
          />
          <img src={SalGraph} alt="Customer age Pivot Table" className="my-2" />
          <p className="text-2xl my-5">
            You can easily see from both the table and the bar graph that
            December was the highest month of sales at $4,616,529.41
          </p>
          <p className="text-2xl my-5">
            The next question I answered was "What city sold the most product?"
            and I again answered it using a table and a graph.
          </p>
          <img src={CitTab} alt="Product Table" className="my-2" />
          <img src={CitGraph} alt="Product Graph" className="my-2" />
          <p className="text-2xl my-5">
            You can see from both the table and the graph that San Francisco had
            the highest Quantity of orders at 50,239.
          </p>

          <p className="text-2xl my-5">
            The next question I answered was "What time of day did most orders
            occur?" by creating a line graph.
          </p>

          <img src={Line} alt="Orders line Graph" className="my-2" />
          <p className="text-2xl my-5">
            You can see from the line graph above that the highest volume of
            orders came between hour 10 and hour 21. This information could be
            especially useful to the marketing team.
          </p>
          <p className="text-2xl my-5">
            The final question I answered was "What product was sold the most?"
            by using another bar graph.
          </p>
          <img
            src={Quantity}
            alt="# of products ordered graph"
            className="my-2"
          />
          <p className="text-2xl my-5">
            You can quickly determine from the bar chart above that batteries
            were the companies best selling product.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Python;
